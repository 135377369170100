<template>

  <div class="body-bg" :style="{'--flexiblePaddingTop':flexiblePaddingTop ? flexiblePaddingTop + 'px' : null}">
    <div style="background: white">
      <div class="header">
        <h1 style="font-weight: 400">新建文档</h1>
        <i class="el-icon-close" @click="$router.push('/contract/files')"></i>
      </div>
      <div style="text-align: left">
        <!--    <el-steps :active="1">-->
        <!--      <el-step title="Step 1" description="Some description"></el-step>-->
        <!--      <el-step title="Step 2" description="Some description"></el-step>-->
        <!--      <el-step title="Step 3" description="Some description"></el-step>-->
        <!--    </el-steps>-->
        <el-progress :percentage="Math.min(Math.max(step/4*100,0),100)" :show-text="false" style="background: white"/>
      </div>
    </div>
    <div ref="flexibleTop" class="content flexible-top" v-if="step===1">

      <div class="title">
        <i class="el-icon-back" @click="$router.push('/contract/files')"></i>
        <span>请选择以下任务</span>
      </div>
      <div class="selection">
        <div class="item mr4" @click="draftContract">
          <div>
            <div>
              <i class="el-icon-s-check"></i>
              <span>起草新合同</span>
            </div>

            <div class="gray">
              <p class="mt3">
                从模板起草、上传文件起草、<wbr>从空文本起草
              </p>
            </div>
          </div>
        </div>
        <div class="item mr4" v-if="!config.noModel" @click="uploadContract">
          <div>
            <div>
            <i class="el-icon-upload"></i>
            <span>上传已签署合同</span>
            </div>
            <div class="gray">
              <p class="mt3">
                上传合同并设置为已签署状态
              </p>
            </div>

          </div>
        </div>
        <div class="item mr3" @click="createTemplate" v-if="!(config.noModel && userInfo.permissions.indexOf('templateApproval')<0)">
          <div>
            <div>
              <i class="el-icon-copy-document"></i>
              <span>模板制作</span>
            </div>
            <div class="gray">
              <p class="mt3">
                新建的模板将添加到团队模板库，以供使用
              </p>
            </div>

          </div>
        </div>

      </div>
    </div>
    <div ref="flexibleTop" class="content flexible-top" v-else-if="step==2 && mode==1 && draftMethod==0">

      <div class="title">
        <i class="el-icon-back" @click="step-=1;draftMethod=0"></i>
        <span>请选择起草新合同的方式</span>
      </div>
      <div class="selection">
        <div class="item mr4 fs2" v-bind:class="{'item-light':draftMethod===1}" @click="draftMethod=1">
          <div class="flex" style="height: 24px" >
            <i class="el-icon-copy-document"></i>
            <span>从模板起草</span>

          </div>
        </div>
        <div class="item mr4 fs2" v-if="!config.noModel" v-bind:class="{'item-light':draftMethod===2}" @click="draftMethod=2">
          <div class="flex" style="height: 24px" >
            <i class="el-icon-upload"></i>
            <span>上传文件起草</span>

          </div>
        </div>
        <div class="item fs2" @click="draftMethod=3;step=3" v-bind:class="{'item-light':draftMethod===3}">
          <div class="flex" style="height: 24px">
            <i class="el-icon-document"></i>
            <span>从空文本起草</span>
          </div>
        </div>

      </div>



    </div>
    <div ref="flexibleTop" class="content flexible-top" v-else-if="step==2 && mode==2">

      <div class="title">
        <i class="el-icon-back" @click="step-=1"></i>
        <span>上传合同并设置为已签署状态</span>
      </div>
      <div style="margin-top: 50px">
        <el-upload
            style="width: 100%;"
            class="upload-demo"
            drag
            accept=".docx,.DOCX,.pdf,.PDF,.doc,.DOC"
            :show-file-list="false"
            :on-success="uploadSuccessFunc"
            :on-error="uploadErrorFunc"
            :before-upload="beforeUploadFunc"
            :action="signedUploadUrl"
            multiple>
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将合同文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <div style="text-align: left">
        有多个文档需要导入，可使用<span style="cursor: pointer; color: #5280FF" @click="openNewPage('bulkUpload', { import: true})">批量上传</span>功能
      </div>

    </div>
    <div ref="flexibleTop" class="content flexible-top" v-else-if="step==2 && mode==3 && createTemplateMethod!==1">

      <div class="title">
          <i class="el-icon-back" @click="step-=1;createTemplateMethod=0"></i>
          <span>请选择新建模板方式</span>
      </div>
      <div class="selection">

        <div class="item mr4" v-bind:class="{'item-light':createTemplateMethod===1}" @click="createTemplateMethod=1">
          <div>
            <div>
              <i class="el-icon-upload"></i>
              <span>上传文件</span><br>
            </div>

            <div class="gray">
              <p class="mt3">
                通过对上传的文件进行在线编<wbr/>辑，制作新模板
              </p>
            </div>
          </div>
        </div>
        <div class="item"  @click="createBlankTemplate">
          <div>
            <div>
              <i class="el-icon-document"></i>
              <span>从空文本新建</span>
            </div>

            <div class="gray">
              <p class="mt3">
                从空文本起草并编辑模板问题
              </p>
            </div>
          </div>
        </div>

      </div>



    </div>

    <div ref="flexibleTop" class="content flexible-top" v-else-if="step==3 && mode==1">

      <div class="title">
        <i class="el-icon-back" @click="step-=1;draftMethod=0"></i>
        <span v-if="draftMethod==2">为上传的合同配置审批流程</span>
        <span v-else>为该合同配置审批流程</span>
      </div>
      <div class="selection">

        <span>此合同将根据您选择的审批流程进行审批：</span>
        <el-select v-model="metaApprovalProcessId"  @change ="onSelectMetaApproval">
          <el-option
              v-for="item in metaApprovalList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>


      </div>
      <div class="approval-pane-area">
        <el-scrollbar max-height="410px">
          <table class="approval-process">
            <tr class=" gray">
              <th class="approval-process-column approval-process-column-1">步骤</th>
              <th class="approval-process-column approval-process-column-2">审批人</th>
              <th class="approval-process-column approval-process-column-3">策略</th>
            </tr >

            <tr
                v-for="(item, index) in metaApprovalProcess.approvalStages"
                :key="index"
            >
              <td class="approval-process-index">
                <span class="approval-process-index-wrap">{{ index + 1 }}</span>
              </td>
              <td class="approval-process-inspector">
                <approval-member  :orders="item.orders" main_placement="right" sub_placement="right-start">
                  <template #reference>
                    <user-icon
                        v-for="(order, index2) in item.orders.slice(0,5)"
                        :key="index2"
                        style="position: relative"
                        :uid="order.approverName"
                        :name="order.approverName
                                                  ? getUserName(order.approverName).substr(0, 1)
                                                  : order.approver.substr(0, 1)"
                        :url="order.approverHeadImgUrl"
                    >
                    </user-icon>
                    <user-icon v-if="item.orders.length>5"
                               :uid="'count'"
                               :name="'+'+((item.orders.length)-5)"
                               :size="'small'"
                               :count="2"
                    ></user-icon>
                  </template>
                </approval-member>

              </td>
              <td class="approval-process-strategy">
                                  <span class="approval-process-strategy-wrap gray">
                                    {{
                                      item.strategy == 0 ? "全部审批人通过" : "任一审批人通过"
                                    }}
                                  </span>

              </td>
            </tr>
          </table>
        </el-scrollbar>
      </div>
      <div style="margin: 30px 0">
        <el-button type="primary" style="width: 300px" @click="createContractAssociation">确定</el-button>
      </div>
    </div>

    <div ref="flexibleTop" class="content flexible-top" v-else-if="step==4 && mode==1">
      <div class="title">
        <i class="el-icon-back" @click="step-=1;draftMethod=0"></i>
        <span v-if="draftMethod==2">为上传的合同配置关联合同</span>
        <span v-else>为该合同配置关联合同</span>
      </div>
      <div style="margin-top: 8px;margin-left:44px;display: flex">
        <span style="color: #868686;font-size: 14px;height: 21px">如果您新建的合同涉及关联交易，可在此处将其链接到现有合同</span>
      </div>
      <div style="margin-top: 40px;display: flex;text-align: left">
        <ul style="list-style-type: none;color: #A4A4A4;font-size: 18px">
          <li style="padding-bottom: 26px">
            <el-radio v-model="contractAssociation" :label = false></el-radio>
            <span>无需关联</span>
          </li>
          <li style="padding-bottom: 30px">
            <el-radio v-model="contractAssociation" :label = true></el-radio>
            <span>关联到现有合同</span>
          </li>
          <li v-if="selectedContract.title && contractAssociation" style="color: #101010;padding-left: 45px;padding-bottom: 15px">
            <span v-html="selectedContract.title"></span>
            <span style="margin-left: 10px" @click="deleteSelectedContract" class="pointer">X</span>
          </li>
          <li v-if="contractAssociation" style="padding-left: 45px;color: #5582FF" class="cursorChange">
            <span @click="selectContractDialogVisible = true" >请选择合同</span>
          </li>
        </ul>
      </div>
      <div style="margin: 40px 0;display: flex;justify-content: center">
        <el-button :disabled="contractAssociation && !selectedContract.title" type="primary" style="width: 300px" @click="createDraft">确定</el-button>
      </div>
    </div>

    <div ref="flexibleTop" class="content flexible-top" v-else-if="step===5" style="flex: 1">

      <h2>正在创建文档，请稍候...</h2>
      <div v-loading="true" style="margin-top: 50px">
      </div>

    </div>

    <div v-if="draftMethod==1 && step==2" ref="flexibleBottom"  class="content" style="padding-top: 10px;position: relative;flex: 1;overflow-y: hidden;width: 900px" >
      <div class="title">
        <i class="el-icon-back" @click="step-=1;draftMethod=0"></i>
        <span>请选择模板</span>
      </div>
      <div style="margin-top: 10px;padding: 2px 2px 0 2px;flex: 1;overflow-y: hidden">
      <div class="template-search"><el-input prefix-icon="el-icon-search" placeholder="搜索模板" size="small" v-model="searchKey" @input="searchTemplate"></el-input></div>
      <el-tabs v-model="activeName"  style="background-color: white;height: 100%" @tab-click="switchTab" stretch>
        <el-tab-pane label="团队模板"  style="background-color: #f4f6fd;height: 100%" :name="BankName.TEAM">
          <div  style="display: flex;height: 100%" v-if="teamTemplateCategories.filter((item)=>teamTemplateMap.get(item.id)).length>0">
            <div style="background-color: white;height:100%">
              <el-scrollbar style="flex: 1;">
                <ul class="cate-list" style="height: 100%" v-if="category">
                  <li tabindex="1"  v-for="c in teamTemplateCategories.filter((item)=>teamTemplateMap.get(item.id))" :key="c.id" v-bind:class="{'li-on':category.id==c.id}" @click="category=c">{{c.name}}</li>

                </ul>
              </el-scrollbar>
            </div>
            <div style="padding: 10px 0 10px 10px;flex: 1;display: flex;flex-direction: column">
              <div class="panel-content-header">
                <el-image :src="document" style="height: 28px;width: 28px"></el-image>
                <h3 v-if="category">{{ category.name }}</h3>
              </div>
              <el-scrollbar style="flex: 1">
                <div class="panel-content-list" v-if="category">
                  <div  class="card"
                        @mouseenter="activeTemplateIndex=template.id"
                        @mouseleave="activeTemplateIndex=null"
                        v-for="template in teamTemplateMap.get(category.id)"  :key="template.id"
                  >
                    <div style="width:153px;height:158px">
                      <div class="list-item-image"></div>
                      <div style="position: relative">
                        <div  :style="activeTemplateIndex===template.id?'filter:blur(3px);':''" style="height: 55px">
                          <div style="margin-bottom: 10px">
                            <span class="list-item-title" style="font-size: 18px">{{ template.title }}</span>
                          </div>

                        </div>

                      </div>
                    </div>
                    <div class="card-info" :class="{'card-info-show':activeTemplateIndex===template.id,'card-info-hide':activeTemplateIndex!==template.id}"  @click="previewTemplate(template.id,template.fileId,template.title)">
                      <div style="min-height: 40px;padding: 10px">
                        <span style="font-size: 16px">{{ template.title }}</span>
                      </div>
                      <div style="margin-bottom: 10px;padding: 0 10px 10px">
                        <span style="font-size: 12px;color: #ffffff9f" v-html="template.description?template.description:'没有描述信息'"></span>
                      </div>
                      <div class="card-info-action">
                        <div class="edit-icon" v-if="userInfo && userInfo.isManagerRole" @click.stop="editTemplate(template.fileId,template.title)">
                          <svg-icon :name="'edit'" :height="22" :width="22"></svg-icon>
                        </div>
                        <div class="entry-icon"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </el-scrollbar>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="系统模板" :name="BankName.SYSTEM" style="background-color: #f4f6fd;height: 100%">
          <div  style="display: flex;height: 100%" v-if="systemTemplateCategories.filter((item)=>systemTemplateMap.get(item.id)).length>0">
            <div style="background-color: white;height: 100%">
              <el-scrollbar style="flex: 1">
              <ul class="cate-list" style="height: 100%" v-if="category">
                <li tabindex="1"  v-for="c in systemTemplateCategories.filter((item)=>systemTemplateMap.get(item.id))" :key="c.id" v-bind:class="{'li-on':category.id==c.id}" @click="category=c">{{c.name}}</li>

              </ul>
              </el-scrollbar>
            </div>
            <div style="padding: 10px 0 10px 10px;flex: 1">
              <div class="panel-content-header">
                <el-image :src="document" style="height: 28px;width: 28px"></el-image>
                <h3 v-if="category">{{category.name}}</h3>
              </div>
              <el-scrollbar >
                <div class="panel-content-list" v-if="category">
                  <div  class="card"
                        @mouseenter="activeTemplateIndex=template.id"
                        @mouseleave="activeTemplateIndex=null"
                        v-for="template in systemTemplateMap.get(category.id)"  :key="template.id"
                  >
                    <div style="width:153px;height:158px">
                      <div class="list-item-image"></div>
                      <div style="position: relative">
                        <div  :style="activeTemplateIndex===template.id?'filter:blur(3px);':''" style="height: 55px">
                          <div style="margin-bottom: 10px">
                            <span class="list-item-title" style="font-size: 18px">{{ template.title }}</span>
                          </div>

                        </div>

                      </div>
                    </div>
                    <div class="card-info" :class="{'card-info-show':activeTemplateIndex===template.id,'card-info-hide':activeTemplateIndex!==template.id}"  @click="previewTemplate(template.id,template.fileId,template.title)">
                      <div style="margin-bottom: 10px;height: 40px;padding: 10px">
                        <span style="font-size: 16px">{{ template.title }}</span>
                      </div>
                      <div style="margin-bottom: 10px;padding: 10px">
                        <span style="font-size: 12px;color: #ffffff9f"  v-html="template.description?template.description:'没有描述信息'"></span>
                      </div>
                      <div class="card-info-action">

                        <div class="entry-icon"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </el-scrollbar>
            </div>
          </div>
        </el-tab-pane>

      </el-tabs>
      </div>
    </div>

    <div class="content flexible-bottom" ref="flexibleBottom" v-else-if="createTemplateMethod===1 && step==2">
      <div class="title">
        <i class="el-icon-back" @click="createTemplateMethod=0"></i>
        <span>上传模板文件</span>
      </div>
      <div style="margin-top: 50px">
        <el-upload
            style="width: 100%;"
            class="upload-demo"
            drag
            accept=".docx,.DOCX"
            :show-file-list="false"
            :on-success="templateUploadSuccessFunc"
            :on-error="templateUploadErrorFunc"
            :before-upload="templateBeforeUploadFunc"
            :action="templateUploadUrl"
            multiple>
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将合同拖到此处，或<em>点击上传</em></div>
          <template #tip>
            <div class="el-upload__tip">
              仅支持 docx 文档
            </div>
          </template>
        </el-upload>
      </div>
    </div>
    <div class="content flexible-bottom"  v-show="draftMethod===2 && step==2">
      <div class="title">
        <i class="el-icon-back" @click="draftMethod=0"></i>
        <span>上传待起草的合同</span>
      </div>
      <div style="margin-top: 50px">
        <el-upload
            ref="uploadRef"
            style="width: 100%;"
            class="upload-demo"
            drag
            accept=".docx,.DOCX,.pdf,.PDF,.doc,.DOC"
            :show-file-list="false"
            :on-success="uploadSuccessFunc"
            :auto-upload="false"
            :on-error="uploadErrorFunc"
            :before-upload="beforeUploadFunc"
            :on-change="onFileChange"
            :action="draftUploadUrl"
            :data="{metaApprovalProcessId:metaApprovalProcessId,associatedContractId:selectedContract.contractId}"
            multiple>
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将合同文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
    </div>

    <el-dialog
            v-model="selectContractDialogVisible"
            title="请选择关联合同"
            width="620px">
      <ContractSelector
              ref="contract-selector"
              :init-recently-modified="true"
              :status-visible="true">
      </ContractSelector>
      <template #footer>
              <span class="dialog-footer">
                <el-button @click="selectContractCancel">取消</el-button>
                <el-button type="primary" @click="selectContractConfirm">确定</el-button>
              </span>
      </template>
    </el-dialog>
  </div>


</template>

<script>
import document from "../../assets/images/document.png"
import fileIcon from  "../../assets/images/icon_wj.png"
import {ContractApprovalStatus} from "../../constant/contract";
import config from "../../config";
import edit from "../../assets/svg/edit.svg"
import {getUserName, MetaLogger} from "../../util";
import {
  createBlankContract,
  createBlankTemplate,
  createShareParam,
  getContractPreviewStatus, getMetaApprovalProcess,
  getSystemTemplateBank, getTeamMetaApprovalProcessDataList, getTeamMetaApprovalProcesses,
  getTeamTemplateBank
} from "../../api/api";
import {ElLoading} from "element-plus";
import SvgIcon from "../../components/SvgIcon/svgIcon";
import {colorLog, MetaMessage} from "../../util";
import {globalTagClick} from "../../assets/js/tag/tag";
import ApprovalMember from "../../components/ApprovalMember";
import UserIcon from "../../components/UserIcon";
import ContractSelector from "../../components/contract/ContractSelector";

const BankName = {
  TEAM:'team',
  SYSTEM:'system'
}
// 文件处理状态
const FileJobStatusType = {
  UNTREATED: 0,
  PROCESSING: 1,
  FINISH: 2,
  //错误状态
  UPDATE_ES_ERROR: 11,
  FILE_IS_DELETED: 12,
  DELETE_ES_ERROR: 13,
  OLD_OPERATION: 14,
  FIND_FILECACHE_TIME_OUT: 15,
  TASK_ERROR: 16,
  EXTRACT_CONTENT_ERROR: 17,
  SEG_ERROR: 19,
  EMPTY_CONTENT: 21,
  SEG_CLASSIFY_ERROR: 22,
  SEG_ERROR_RETRY_LIMIT: 109
}
export default {
  name: "NewDocument",
  components: {UserIcon, ApprovalMember, SvgIcon, ContractSelector},
  data() {
    return {
      step: this.$route.query.step || 1,
      mode: this.$route.query.mode || 1,
      draftMethod:this.$route.query.dm || 0,
      createTemplateMethod:0,
      activeName:BankName.TEAM,
      document,
      fileIcon,
      category: {},
      activeTemplateIndex:null,
      contractBank:'team',
      BankName:BankName,
      loadingObj:{},
      loadSuccessObj:{},
      teamTemplates:[],
      teamTemplateCategories:[],
      ContractApprovalStatus,

      systemTemplates:[],
      systemTemplateCategories:[],
      edit,
      createContractFreeze:false,
      config:config,
      signedUploadUrl: config.baseUrl + 'contract/upload-as-signed',
      draftUploadUrl: config.baseUrl + 'contract/upload-as-draft',
      templateUploadUrl: config.baseUrl + "/template/upload",
      loadingInstance:null,
      queryConvertStatusTimeout:null,
      contract:null,
      contractStatus:ContractApprovalStatus.DRAFT,
      userInfo:JSON.parse(sessionStorage.getItem("userInfo")),
      searchKey:'',
      metaApprovalList:[],
      metaApprovalProcess:{},
      flexiblePaddingTop:null,
      metaMessage:new MetaMessage(),
      getUserName,
      contractAssociation: false,
      selectContractDialogVisible: false,
      selectedContract: {
        contractId: "",
        title: ""
      },
      metaApprovalProcessId:null,
    }
  },
  computed:{
    teamTemplateMap(){
      let map = new Map();
      this.teamTemplates.forEach((template)=>{
        if(template.title.indexOf(this.searchKey)>=0){
          if (!map.has(template.categoryId)) {
            map.set(template.categoryId, [])
          }
          map.get(template.categoryId).push(template);
        }

      })
      return map;
    },
    systemTemplateMap(){
      let map = new Map();
      this.systemTemplates.forEach((template)=>{
        if(template.title.indexOf(this.searchKey)>=0){
          if (!map.has(template.categoryId)) {
            map.set(template.categoryId, [])
          }
          map.get(template.categoryId).push(template);
        }
      })
      return map;
    },
  },
  mounted() {
    console.log('newDocument  mounted  this.step === ', this.step);
    console.log('newDocument  mounted  this.mode === ', this.mode);

    this.loadData(this.contractBank)
    this.loadMetaApproval()
  },
  created() {
    //获取浏览器内容区域最大高度
    let maxHeight = screen.height - 40 - (window.outerHeight - window.innerHeight);
    let maxWidth = screen.width;
    let ratio  =  maxWidth / maxHeight;
    colorLog.blue('ratio',ratio)
    if(ratio > 2.95){
      this.checkoutScreenSize(maxHeight);
    }
  },
  methods:{
    checkoutScreenSize(maxHeight){
      let mainAreaHeight = maxHeight - 70;
      let paddingTop = (mainAreaHeight - 662) / 2;
      colorLog.red('paddingTop',paddingTop);
      this.flexiblePaddingTop = paddingTop;
    },
    showVisible(boolean){
      if(boolean){
      }else{
        this.loadMetaApproval()
      }
    },
    loadData:function (bankName) {
      if (this.loadingObj[bankName] || this.loadSuccessObj[bankName]) {
        switch (bankName) {
          case BankName.TEAM:
            if (this.teamTemplateCategories && this.teamTemplateCategories.length > 0) {
              this.category = this.teamTemplateCategories.filter((item)=>this.teamTemplateMap.get(item.id))[0];
            }
            break;
          case BankName.SYSTEM:
            if (this.systemTemplateCategories && this.systemTemplateCategories.length > 0) {
              this.category = this.systemTemplateCategories.filter((item)=>this.systemTemplateMap.get(item.id))[0];
            }
            break;
        }
        return;
      }
      this.loadingObj[bankName] = true;
      this.requestBankData(bankName).then(res => {
        if (res.data.code === 0) {
          this.loadSuccessObj[bankName] = true;
          switch (bankName) {
            case BankName.TEAM: {
                this.teamTemplates = res.data.data.templates;
                this.teamTemplateCategories = res.data.data.categories;
                if (this.teamTemplateCategories && this.teamTemplateCategories.length > 0) {
                  this.category = this.teamTemplateCategories.filter((item)=>this.teamTemplateMap.get(item.id))[0];
                }
              }break;
            case BankName.SYSTEM:{
              this.systemTemplates = res.data.data.templates;
              this.systemTemplateCategories = res.data.data.categories;
              if(this.systemTemplateCategories&&this.systemTemplateCategories.length>0){
                this.category = this.systemTemplateCategories.filter((item)=>this.systemTemplateMap.get(item.id))[0];
              }
            }break;
          }
        }

      }).catch(error => {
        console.error(error);
      }).finally(() => {
        this.loadingObj[bankName] = false;
      });
    },
    loadMetaApproval(){
      getTeamMetaApprovalProcesses().then(res=>{
        if(res.status==200){
          if(res.data.code==0){
            this.metaApprovalList = res.data.data;
            let item = this.metaApprovalList.find((item)=>item.name==='管理员审批');
            if(item!=null){
              this.metaApprovalProcessId=item.id;
            }else{
              if(this.metaApprovalList.length>0){
                this.metaApprovalProcessId=this.metaApprovalList[0].id;

              }
            }
            if(this.metaApprovalProcess!=null){
              this.loadMetaApprovalProcess();
            }
          }
        }
      })
    },
    loadMetaApprovalProcess(){
      getMetaApprovalProcess(this.metaApprovalProcessId).then(res=>{
        if(res.data.code==0) {
          this.metaApprovalProcess = res.data.data;
        }
      }).catch(err=>{
        MetaLogger.error(err);
        this.metaMessage.error('获取审批流程失败');
      })
    },
    requestBankData:function (bankName) {
      if (bankName === BankName.TEAM) {
        return getTeamTemplateBank();
      }else if (bankName === BankName.SYSTEM){
        return getSystemTemplateBank();
      }
    },
    draftContract(){
      this.step=2;
      this.mode=1;
    },
    uploadContract(){
      // this.$message.info("正在开发中")
      // return;
      this.step=2;
      this.mode=2;
    },
    createTemplate(){
      this.step=2;
      this.mode=3;
    },
    showTemplateList(){

    },
    switchTab(tab) {
      this.contractBank=tab.paneName;
      this.loadData(tab.paneName);
    },
    previewTemplate(templateId,fileId,fileName){
      globalTagClick('new-document-preview-template');
      this.$router.push({name:'doc',query:{fileId:fileId,fileName:fileName,mode:'answer_model',templateId:templateId}})
    },
    editTemplate(fileId,fileName){
      this.$router.push({name:'doc',query:{fileId:fileId,fileName:fileName,mode:'edit_model'}})
    },
    startToAnswerQuestion: function (templateId) {
      this.step=5
      if (this.createContractFreeze) {
        return;
      }
      this.createContractFreeze = true;

      createShareParam(templateId).then(res => {
        if (res.data.code === 0) {
          let key = res.data.data
          this.jumpToAnswerQuestion(key);
        }
      }).catch(error => {
        console.error(error);
      }).finally(()=>{
        this.createContractFreeze = false;
      });
    },
    jumpToAnswerQuestion:function (dataKey) {
      let resolveObj = {
        name: 'answerQuestion',
        query: {
          data: dataKey
        }
      };
      this.$router.push(resolveObj);
    },
    beforeUploadFunc:function(){
      this.step=5
    },
    uploadSuccessFunc:function (res,file,fileList) {
      let type;
      if(this.mode==2){
        type = 'signed';
      }else if(this.draftMethod == 2){
        type = 'draft';
      }
      globalTagClick(`new-document-upload-${type}-contract`)
      if (res.code === 0) {

        console.log('uploadSuccess', res);
        this.contract = res.data;
        this.queryContractConvertStatus(this.contract);

      }else if(res.msg){

        this.$message.error(res.msg);
        this.step=2;
      }else{
        this.$message.error("暂无权限");
      }
    },
    uploadErrorFunc: function (err, file, fileList){
      this.$message.error('上传失败');
      this.step=2;

    },
    queryContractConvertStatus:function(contract){
      this.jumpToContractDetail(contract.id,contract.title);
    },
    jumpToContractDetail: function (id, title) {
      this.$router.push({name: 'contractView', query: {id: id, name: title}})
    },
    searchTemplate(){
      switch (this.contractBank){
        case BankName.TEAM: {
          let cates = this.teamTemplateCategories.filter((item) => this.teamTemplateMap.get(item.id));
          if (cates.length>0){
            this.category=cates[0];
          }
        }break;
        case BankName.SYSTEM: {
          let cates = this.systemTemplateCategories.filter((item) => this.systemTemplateMap.get(item.id));
          if (cates.length>0){
            this.category=cates[0];
          }
        }break;
      }

    },
    templateUploadSuccessFunc: function (res, file, fileList) {
      globalTagClick('new-document-upload-template');
      this.editCallBack(res);
    },
    templateUploadErrorFunc: function (err, file, fileList) {
      console.error('templateUploadErrorFunc  err :', err);
      let errorString = err.toString();
      errorString = errorString.replace('Error: ', '')
      const error = JSON.parse(errorString);
      this.$message.error('上传失败:' + error.message);
      this.step=2;
    },
    templateBeforeUploadFunc: function () {
      this.step=5;
    },
    editCallBack: function (result) {
      let code = result["code"];
      if (code === 0) {
        let fileId = result.data.fileId;
        let title = result.data.title;
        this.$router.push({name:'doc',query:{fileId:fileId, title: title}})
      } else {
        let message = result["msg"];
        this.$message.error(message);
      }
      this.freeze = false;

    },
    createBlankTemplate(){
      globalTagClick('new-document-create-blank-template');
      this.step=5;
      createBlankTemplate().then(res=>{
        if(res.status===200){
          this.editCallBack(res.data)
        }

      }).catch(e=>{
        this.$message.error("新建文档失败,暂无权限");
        this.step=2;
      })

    },
    createBlankContract(){
      globalTagClick('new-document-create-blank-contract',{metaCategory:this.metaApprovalProcessId});
      this.step=5;
      createBlankContract(ContractApprovalStatus.DRAFT,this.metaApprovalProcessId,this.selectedContract.contractId).then(res=>{
        this.contract = res.data.data;
        this.$router.push({
          name:'contractEdit',
          query:{
            contractId:res.data.data.id,
          }
        })

      }).catch(e=>{
        this.$message.error("新建文档失败,暂无权限");
        this.step=2;
      })
    },
    openNewPage: function (name, query) {
      let newPage = this.$router.resolve({
        name: name,
        query: query
      })
      window.open(newPage.href);
    },
    onFileChange(e){
      if(this.step==2){
        this.step=3
      }
    },
    onSelectMetaApproval(){
      this.loadMetaApprovalProcess()
    },
    createDraft(){
      if (!this.contractAssociation){
        this.selectedContract.contractId = "";
        this.selectedContract.title = null;
      }

      if(this.draftMethod===2){
        this.$refs.uploadRef.submit();
      }else if(this.draftMethod===3){
        this.createBlankContract();
      }
    },
    createContractAssociation(){
      this.step = 4;
    },
    selectContractCancel(){
      this.selectContractDialogVisible = false;
      this.selectedContract = null;
    },
    selectContractConfirm: function () {
      let contractSelect = this.$refs['contract-selector'].selectedContract;

      if (contractSelect != null) {
        this.selectedContract.title = contractSelect.title;
        this.selectedContract.contractId = contractSelect.id;
      }else{
        this.selectedContract.title = null;
        this.selectedContract.contractId = null;
      }
      this.selectContractDialogVisible = false;
    },
    deleteSelectedContract(){
      this.selectedContract.title = null;
      this.selectedContract.contractId = null;
    }
  }
}
</script>

<style scoped>

  .card-info strong{
    color:white;
    font-size: 14px;
  }


.body-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow-y: auto;
  background: #F8F9FA;
  display: flex;
  flex-direction: column;

}

.header {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header i{
  cursor: pointer;
  border-radius: 5px;
  font-size: 24px;
  padding: 10px;
  font-weight: bold;
}

.header i:hover{
  background: #d0d0d0;
}

.header h1 {
  margin: 0;
}

.content {

  width: 800px;
  margin: 0 auto;
  padding-top: 112px;
  display: flex;
  flex-direction: column;
}
.content.flexible-bottom,.content.flexible-top{
  padding-top: var(--flexiblePaddingTop,112px);
}
.content .title {
  font-size: 24px;
  text-align: left;
}

.content .title i {
  cursor: pointer;
  border-radius: 4px;
  padding: 4px;
  color: #0a84ff;
  margin-right: 10px;
}

.content .title i:hover {
  background: #e0e0e0;
}

.selection {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}

.mt3 {
  margin-top: 1rem;
}

.mr4 {
  margin-right: 2rem;
}

.gray {
  color: #777;
}

.item {
  background: white;
  cursor: pointer;
  border: solid 1px white;
  text-align: left;
  font-size: 20px;
  padding: 28px;
  box-shadow: rgb(0 0 0 / 25%) 0px 0px 10px;
  width: 33.33333%;
  /*display: flex;*/
  /*align-items: center;*/
  /*flex-direction: column;*/
  /*justify-content: space-between;*/

}

.item:hover {
  border: solid 1px #0a84ff;
}

.item i {
  color: #0a84ff;
  margin-right: 10px;
}

.item p {
  font-size: 14px;
}
.fs2{
  font-size: 16px;
}
.flex{
  display: flex;
  align-items: center;
}

.item-light{
  background:#E4EBFF ;
  border-color: #0a84ff;
}

.content >>> .el-upload,.content >>> .el-upload-dragger{
  width: 100% !important;;
}

.content >>> .el-tabs .el-tabs__item{
  height: auto;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;

  font-family: '黑体';
}


.content >>> .el-tabs. el-tabs__active-bar{
  width: 84px;
}

.content >>> .el-tabs{
  box-shadow: rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.content >>> .el-tabs__nav-prev{
  display: none;
}
.content >>> .el-tabs__nav-next{
  display: none;
}
.content .panel-content-header{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.content .panel-content-header h3{
  margin: 0;
}

.content >>> .el-tabs .el-tabs__header{
  margin: 0;
}

.content .panel-content-list{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex: 1;
}

.panel-content-list .card{
  /*border-top: solid 4px #0a84ff;*/
  margin-bottom: 10px;
  margin-right: 12px;
  border-radius: 5px;
  padding: 41px 10px;
  background: white;
  border:solid 1px #ddd;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.card-info-show{
  /*opacity: 1.0;*/
  transition: transform 0.8s cubic-bezier(0.2, 0.84, 0.35, 1), opacity 0.5s cubic-bezier(0, 0, 0, 1) 0.1s;
}
.card-info-hide{
  transform: translateY(-300px);
  /*opacity: 0.0;*/
  transition: transform 0.8s cubic-bezier(0.2, 0.84, 0.35, 1), opacity 0.5s cubic-bezier(0, 0, 0, 1) 0.1s;
}
.card-info{

  position: absolute;
  height: 100%;
  width: 100%;
  background: #1C4DC5;
  top: 0;
  left: 0;
  cursor: pointer;
  color: white;
  border-radius: 5px;
  text-align: left;


}

.card-info-action{
  text-align: right;
  position: absolute;
  bottom: 10px;
  width: 100%;
  height: 24px;
}

.entry-icon{
  background: url(../../assets/images/icon_xy.png) center;
  width: 24px;
  height: 24px;
  background-size: 49px;
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;

}
.edit-icon{
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
}
.panel-content-list .card:nth-child(4n){
  margin-right: 0;
}
.content .cate-list{
  margin: 0;
  list-style-type: none;
  padding: 0;

}

.li-on{
  border-left: 4px solid #0a84ff !important;
  background-color: #e6efff;
  color: #4ea6ff;
}

.content .cate-list{
  width: 140px;
}

.content .cate-list li{
  text-align: left;
  display: block;

  padding: 10px;
  cursor: pointer;
  border-left: 4px solid #fff;
}

.content .cate-list li:hover{
  border-left: 4px solid #dedede;
  background-color: #dedede;
}

.content .cate-list li:focus{

}

.content .template-search{
  position: absolute;
  right: 10px;
  top: 67px;
  z-index: 9999;
}

.content .template-search >>> input{
  border-radius: 20px;
}

.panel-content-list .list-item-image{
  height: 70px;width: 67px;
  margin: 0 auto;
  background: url(../../assets/images/icon_wj.png) center /contain no-repeat;

}

.content .panel-content-list .card >>> .list-item-title{

  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
  display:-webkit-box;

  -webkit-box-orient:vertical;

  -webkit-line-clamp:3;
}

.content >>> .el-tabs{
  display: flex;
  flex-direction: column;
}

.content >>> .el-tabs__content{
  flex: 1;
}
.content >>> .el-tabs__nav-scroll{
  width: 30% !important;
  margin: 0 auto!important;
}

/*.content >>> .el-tabs__nav{*/
/*  float: unset;*/
/*}*/

  .approval-pane-area{
    padding-left: 7px;
    --approval-table-row-height:40px;
  }

  .approval-process{
    width:100%;
    table-layout: fixed;
    border-collapse:collapse;
  }
  .approval-process td{
    text-align: center;
    position:relative;
  }
  .approval-process tr{
    height: var(--approval-table-row-height);
    border-bottom: solid 1px #dedede;
  }
  .approval-process th{
    user-select:none;
    font-weight: bold;
    line-height: var(--approval-table-row-height);
  }
  .approval-process tr:hover:not(:first-child){
    background-color: var(--hoverLightBlue);
  }

  .approval-process-column{
    text-align: center;
    white-space: nowrap;
  }
  .approval-process-column-1{
    width:2em;
  }
  .approval-process-column-2{
    padding-right:50%;
    width:auto;
  }
  .approval-process-column-3{
    width:10rem;
  }
  .approval-process-inspector{

  }
  .approval-process-inspector >>> .organization{
    width:fit-content;
    margin-left:calc(13% + 12px);
    margin-right:auto;
    margin-top: auto;
    margin-bottom: auto;
    position: absolute;
    top:0;
    left: 0;
    bottom:0;
    right:0;
  }
  .approval-process-index{
  }
  .approval-process-index-wrap,
  .approval-process-strategy-wrap{
    display:block;
    height: fit-content;
    width:fit-content;
    position:absolute;
    top:0;
    left: 0;
    bottom:0;
    right:0;
    margin:auto;
  }
  .approval-process td>div{
    display:block;
    position:absolute;
    top:0;
    left: 0;
    bottom:0;
    right:0;
    margin:auto;
  }
  .approval-process .user-icon-container{
    margin-right: -5px;
    left: -5px;
  }
  .is-checked + span{
    color: #101010;
  }
  /deep/ .el-radio{
    margin-right: 26px;
  }
  /deep/ .el-radio__label{
    display: none;
  }
  /deep/ .el-radio__inner{
    width: 20px;
    height: 20px;
    background-color: white;
  }
  /deep/ .el-radio__inner::after{
    width: 14px;
    height: 14px;
    background-color: #5280FF;
    border: 2px solid white;
  }
</style>
